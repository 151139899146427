<template>
    <div class="discounts">
        <TheNavbar
            class="discounts__navbar"
            :list="navbarList"
            @itemClickHandler="scrollToCategory"
            @deleteClickHandler="showPopupCategory"
        >
            <div
                v-if="isAdmin"
                class="navbar__button navbar__button_add"
                @click="showPopupCategory({clickMode: 'add'})"
            >
                + Добавить категорию
            </div>
        </TheNavbar>
        <div
            ref="content"
            class="discounts__content content"
        >
            <TheHeader
                class="discounts__header"
                title="Скидки"
            >
                <div
                    v-if="isAdmin"
                    class="header__button-add"
                    @click="showPopupDiscount('add', {})"
                >
                    + Добавить скидку
                </div>
            </TheHeader>
            <TableSection
                v-for="category in categories"
                ref="category"
                :key="category.id"
                :category="category"
                :is-editable="isAdmin"
                :categories-names="categoriesNames"
                @updateCategory="updateCategory"
            />
        </div>
        <PopupCategory/>
        <PopupDiscount
            :categories="categories"
        />
    </div>
</template>

<script>
    import TableSection from '../components/Discounts/TableSection';
    import TheHeader from '../components/TheHeader';
    import TheNavbar from '../components/TheNavbar';
    import PopupCategory from '../components/Discounts/PopupCategory';
    import PopupDiscount from '../components/Discounts/PopupDiscount';
    import apiCategories from '../api/apiCategories';
    import apiDiscounts from '../api/apiDiscounts';
    import {eventBus} from '../main';
    import {
        mapGetters,
    } from 'vuex';
    
    export default {
        name: 'Discounts',
        components: {
            PopupCategory,
            PopupDiscount,
            TheNavbar,
            TheHeader,
            TableSection,
        },
        data() {
            return {
                categories: [],
            };
        },
        computed: {
            ...mapGetters('auth', ['userRole']),
            isAdmin() {
                return this.userRole ? this.userRole.toLowerCase() === 'admin' : false;
            },
            categoriesNames() {
                return this.categories.map((category) => category.name);
            },
            navbarList() {
                return this.categories.map((category) => ({
                    name: category.name,
                    id: category.id,
                    isDeletable: this.isAdmin,
                    clickMode: 'delete',
                }));
            },
        },
        created() {
            this.getAllDiscounts();
        },
        mounted() {
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },
        methods: {
            addEventListeners() {
                eventBus.$on('applyClick', this.applyClick);
                eventBus.$on('scrollToCategory', this.scrollToCategory);
            },
            removeEventListeners() {
                eventBus.$off('applyClick', this.applyClick);
                eventBus.$off('scrollToCategory', this.scrollToCategory);
            },
            scrollToCategory(category) {
                const targetCategory = this.$refs.category.find((c) => c.$options.propsData.category.id === category.id);
                const el = targetCategory.$el;
                const content = this.$refs.content;
                content.scrollTop = content.offsetTop + el.offsetTop - 90;
            },
            getAllDiscounts() {
                return apiDiscounts.getDiscounts()
                    .then((response) => {
                        this.categories = response.data;
                    })
            },
            addDiscount(discount) {
                const sendObj = {
                    name: discount.name,
                    discountValue: discount.discountValue,
                    organizationLink: discount.organizationLink,
                    description: discount.description,
                    organizationAddress: discount.organizationAddress,
                    categoryId: discount.categoryId,
                };
                return apiDiscounts.addDiscount(sendObj)
                    .then((response) => {
                        const newDiscount = {
                            name: discount.name,
                            discountValue: discount.discountValue,
                            organizationLink: discount.organizationLink,
                            description: discount.description,
                            organizationAddress: discount.organizationAddress,
                            id: response.data,
                        };
                        const targetCategory = this.categories.find((category) => category.id === discount.categoryId);
                        targetCategory.discounts.push(newDiscount);
                    })
            },
            updateDiscount(discount) {
                const sendObj = {
                    discountId: discount.id,
                    data: {
                        name: discount.name,
                        discountValue: discount.discountValue,
                        organizationLink: discount.organizationLink,
                        description: discount.description,
                        organizationAddress: discount.organizationAddress,
                        categoryId: discount.categoryId,
                    },
                };
                return apiDiscounts.updateDiscount(sendObj)
                    .then(() => {
                        const updatedDiscount = {
                            name: discount.name,
                            discountValue: discount.discountValue,
                            organizationLink: discount.organizationLink,
                            description: discount.description,
                            organizationAddress: discount.organizationAddress,
                            id: discount.id,
                        };
                        const targetCategoryIndex = this.categories.findIndex((category) => category.id === discount.categoryId);
                        const targetDiscountIndex = this.categories[targetCategoryIndex].discounts.findIndex((d) => d.id === discount.id);
                        this.categories[targetCategoryIndex].discounts.splice(targetDiscountIndex, 1, updatedDiscount);
                    })
            },
            deleteDiscount(discount) {
                const sendObj = {
                    discountId: discount.id,
                };
                return apiDiscounts.deleteDiscount(sendObj)
                    .then(() => {
                        const targetCategoryIndex = this.categories.findIndex((category) => category.id === discount.categoryId);
                        const targetDiscountIndex = this.categories[targetCategoryIndex].discounts.findIndex((d) => d.id === discount.id);
                        this.categories[targetCategoryIndex].discounts.splice(targetDiscountIndex, 1);
                    })
            },
            addCategory(category) {
                const sendObj = {name: category.name};
                return apiCategories.addCategory(sendObj)
                    .then((response) => {
                        const newCategory = {
                            id: response.data,
                            name: category.name,
                            discounts: [],
                        };
                        this.categories.push(newCategory);
                    });
            },
            deleteCategory(category) {
                const sendObj = {
                    id: category.id
                }
                return apiCategories.deleteCategory(sendObj).then(() => {
                    const index = this.categories.findIndex((c) => c.id === category.id);
                    this.categories.splice(index, 1);
                });
            },
            updateCategory(category) {
                return apiCategories.updateCategory(category).then(() => {
                    const targetCategory = this.categories.find((c) => c.id === category.id);
                    targetCategory.name = category.name;
                });
            },
            showPopupDiscount(mode, discount) {
                const data = {
                    mode,
                    discount: Object.assign({categoryId: 0}, discount),
                }
                eventBus.$emit('initPopupDiscount', data);
            },
            showPopupCategory(params) {
                const data = {
                    mode: params.clickMode,
                }
                if (data.mode === 'delete') {
                    data.category = {
                        name: params.name,
                        id: params.id,
                        categoriesNames: this.categoriesNames,
                    }
                } else if (data.mode === 'add') {
                    data.category = {
                        categoriesNames: this.categoriesNames,
                    }
                }
                eventBus.$emit('initPopupCategory', data);
            },
            applyClick(methodName, item) {
                this[methodName](item);
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .discounts {
        overflow-x: hidden;
        scroll-behavior: smooth;
        display: grid;
        grid-template-columns: 300px 1fr;
        background-color: $beige;
        
        &__navbar {
            position: fixed;
            grid-row-end: 2;
            z-index: 3;
        }
        
        &__header {
            position: sticky;
            top: 0;
            grid-column-start: 2;
            width: 100%;
            padding: 0 40px 0 0;
            z-index: 10;
        }
        
        .content {
            height: calc(100vh - 40px);
            grid-column-start: 2;
            width: calc(100% - 80px);
            padding: 0 40px 40px 40px;
            overflow-y: auto;
            overflow-x: hidden;
            scroll-behavior: smooth;
            
            .section:not(:last-child) {
                margin-bottom: 50px;
            }
        }
    }
</style>
