import apiHelpers from './apiHelpers';

export default {
    getCategories() {
        return apiHelpers.httpRequest('/Category', {}, 'get');
    },
    addCategory(data) {
        return apiHelpers.httpRequest('/Category', data, 'post');
    },
    updateCategory(data) {
        return apiHelpers.httpRequest(`/Category/${data.id}`, {name: data.name}, 'put');
    },
    deleteCategory(data) {
        return apiHelpers.httpRequest(`/Category/${data.id}`, {}, 'delete');
    },
};