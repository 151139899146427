import apiHelpers from './apiHelpers';

export default {
    getDiscounts() {
        return apiHelpers.httpRequest('/Discount', {}, 'get');
    },
    addDiscount(data) {
        return apiHelpers.httpRequest('/Discount', data, 'post');
    },
    updateDiscount(data) {
        return apiHelpers.httpRequest(`/Discount/${data.discountId}`, data.data, 'put');
    },
    deleteDiscount(data) {
        return apiHelpers.httpRequest(`/Discount/${data.discountId}`, {}, 'delete');
    },
};