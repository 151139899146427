<template>
    <div class="section">
        <input
            v-model="localCategoryName"
            ref="categoryName"
            class="section__title"
            :disabled="!isEditable"
            maxlength="30"
            @focusout="compareCategoryName"
            @keyup.enter="$refs.categoryName.blur()"
        >
        <div class="section__header header">
            <div class="header__column"></div>
            <div class="header__column">
                наименование
            </div>
            <div class="header__column">
                скидка
            </div>
            <div class="header__column">
                сайт
            </div>
            <div class="header__column">
                описание
            </div>
            <div class="header__column">
                адрес
            </div>
        </div>
        <TableRow
            v-for="(discount, discountIndex) in category.discounts"
            :key="discountIndex"
            :discount="discount"
            :category-id="category.id"
            :is-editable="isEditable"
            class="section__row"
        />
    </div>
</template>

<script>
    import TableRow from './TableSection/TableRow';
    
    export default {
        name: 'TableSection',
        components: {
            TableRow,
        },
        props: {
            category: {
                type: Object,
                required: true,
                default: () => ({}),
            },
            categoriesNames: {
                type: Array,
                required: true,
                default: () => ([]),
            },
            isEditable: {
                type: Boolean,
                required: true,
                default: false,
            },
        },
        data() {
            return {
                localCategoryName: this.category.name,
            };
        },
        computed: {
            otherCategoriesNames() {
                return this.categoriesNames.filter((categoriesName) => categoriesName !== this.category.name);
            },
        },
        methods: {
            compareCategoryName() {
                const isUniqueName = !this.otherCategoriesNames.some((name) => name.trim().toLowerCase() === this.localCategoryName.trim().toLowerCase());
                if (this.localCategoryName !== this.category.name && this.localCategoryName.trim().length && isUniqueName) {
                    const updatedCategory = {
                        id: this.category.id,
                        name: this.localCategoryName,
                    }
                    this.$emit('updateCategory', updatedCategory);
                } else {
                    this.localCategoryName = this.category.name;
                }
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .section {
        width: 100%;
        height: auto;
        text-align: left;
        
        &__title {
            font-family: 'Nunito', sans-serif;
            width: 100%;
            height: 26px;
            font-size: 22px;
            line-height: 26px;
            font-weight: 700;
            color: $greyDark;
            margin-bottom: 15px;
            background-color: $beige;
            outline: none;
            border: none;
        }
        
        .header {
            display: grid;
            grid-template-columns: 35px .7fr .3fr .5fr 1fr .4fr 60px;
            border-bottom: 1px solid $greyLite;
            
            &__column {
                color: $greyDark;
                opacity: .7;
                font-size: 12px;
                font-weight: 700;
                line-height: 20px;
                text-transform: uppercase;
                padding: 15px;
            }
        }
    }
</style>