<template>
    <div
        class="VSelect"
        :class="{
            'VSelect_invalid': isInvalid,
        }"
    >
        <div
            ref="VSelectInput"
            class="VSelect__input"
            :class="{
                'VSelect__input_placeholder': !localValue && placeholder,
            }"
            @click="changeDropdownVisibility"
        >
            {{ localValue || placeholder }}
        </div>
        <div
            v-show="isShowDropdownMenu"
            class="VSelect__dropdown-menu dropdown-menu"
        >
            <div
                v-for="(option, optionIndex) in options"
                :key="optionIndex"
                class="dropdown-menu__option"
                @click="selectOption(option)"
            >
                {{ option[optionLabelName] }}
            </div>
        </div>
    </div>
</template>

<script>
    import clickOutside from '../mixins/clickOutside';
    
    export default {
        name: 'VSelect',
        mixins: [
            clickOutside,
        ],
        props: {
            options: {
                type: Array,
                default: () => ([]),
            },
            optionLabelName: {
                type: String,
                default: 'name',
            },
            optionValueName: {
                type: String,
                default: 'id',
            },
            placeholder: {
                type: String,
                default: '',
            },
            value: {
                type: [Number, String],
                default: 0,
            },
            isInvalid: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                isShowDropdownMenu: false,
                localValue: null,
            };
        },
        methods: {
            changeDropdownVisibility() {
                this.isShowDropdownMenu = !this.isShowDropdownMenu;
                if (this.isShowDropdownMenu) {
                    this.initClickOutsideListener({
                        key: 'vSelect',
                        el: this.$refs.VSelectInput,
                        callback: () => {
                            this.isShowDropdownMenu = false;
                        },
                    });
                } else {
                    this.destroyEventClickOutsideListener('vSelect');
                }
            },
            selectOption(option) {
                this.localValue = option[this.optionLabelName];
                this.$emit('input', option[this.optionValueName]);
            }
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .VSelect {
        width: 100%;
        position: relative;
        
        &__input {
            cursor: pointer;
            display: flex;
            align-items: center;
            width: calc(100% - 18px);
            height: 54px;
            border-radius: 4px;
            padding-left: 16px;
            border: 1px solid $greyLite;
            outline: none;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: $greyDark;
            &_placeholder {
                color: $greyMid;
            }
        }
        .dropdown-menu {
            position: absolute;
            top: 58px;
            width: calc(100% - 2px);
            background-color: $white;
            border-radius: 4px;
            border: 1px solid $greyLite;
            max-height: 242px;
            z-index: 2;
            overflow-y: auto;
            &__option {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                cursor: pointer;
                display: flex;
                height: 40px;
                align-items: center;
                padding-left: 16px;
                &:hover {
                    background-color: $greyLiter;
                }
            }
        }
        &_invalid {
            .VSelect__input {
                border: 1px solid $red;
            }
        }
    }
</style>