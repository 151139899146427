<template>
    <div
        v-if="isShowedPopup"
        class="popup"
        :class="{
            'popup_small': isDeleteMode,
        }"
    >
        <div class="popup__background"></div>
        <div
            class="popup__wrapper wrapper"
            :class="{
                'wrapper_with-scroll': isShowedScrollbar,
            }"
        >
            <div
                class="button-close"
                @click="closePopup"
            ></div>
            <div class="wrapper__header">
                {{ modeState.title }}
            </div>
            <div class="wrapper__body">
                <template v-if="isEditMode || isAddMode">
                    <div
                        v-if="isAddMode"
                        class="wrapper__body__input input input_category"
                    >
                        <div class="input__label">
                            Категория
                        </div>
                        <VSelect
                            v-model="localItem.categoryId"
                            :options="categories"
                            :is-invalid="!validation.categoryId && isValidationInitialized"
                            placeholder="Выберите категорию"
                        />
                    </div>
                    
                    <div
                        class="wrapper__body__input input input_name"
                        :class="{
                            'input_not-valid': !validation.name && isValidationInitialized,
                        }"
                    >
                        <div class="input__label">
                            Наименование
                        </div>
                        <input
                            v-model="localItem.name"
                            placeholder="Введите наименование скидки"
                            maxlength="150"
                        >
                    </div>
                    <div
                        class="wrapper__body__input input input_value input_postfixed"
                        :class="{
                            'input_not-valid': !validation.discountValue && isValidationInitialized,
                        }"
                    >
                        <div class="input__label">
                            Скидка
                        </div>
                        <input
                            v-model="localItem.discountValue"
                            placeholder="0"
                            maxlength="6"
                        >
                    </div>
                    <div
                        class="wrapper__body__input input input_link"
                        :class="{
                            'input_not-valid': !validation.organizationLink && isValidationInitialized,
                            'input_link_not-valid': !validation.organizationLink && isValidationInitialized,
                        }"
                    >
                        <div class="input__label">
                            Сайт
                        </div>
                        <input
                            v-model="localItem.organizationLink"
                            placeholder="Введите ссылку (прим: http://site.com)"
                            maxlength="60"
                        >
                    </div>
                    <div
                        class="wrapper__body__input input input_description"
                        :class="{
                            'input_not-valid': !validation.description && isValidationInitialized,
                        }"
                    >
                        <div class="input__label">
                            Описание
                        </div>
                        <textarea
                            v-model="localItem.description"
                            placeholder="Введите описание скидки"
                            maxlength="1100"
                        />
                    </div>
                    <div class="wrapper__body__input input input_address">
                        <div class="input__label">
                            Адрес
                        </div>
                        <input
                            v-model="localItem.organizationAddress"
                            placeholder="Введите адрес"
                            maxlength="90"
                        >
                    </div>
                </template>
            </div>
            <div class="wrapper__footer">
                <div
                    class="wrapper__footer__button wrapper__footer__button_cancel"
                    @click="closePopup"
                >
                    {{ modeState.cancelButtonText }}
                </div>
                <div
                    class="wrapper__footer__button wrapper__footer__button_apply"
                    @click="tryToApply"
                >
                    {{ modeState.applyButtonText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../../main';
    import VSelect from '../VSelect';
    
    export default {
        name: 'PopupDiscount',
        components: {
            VSelect,
        },
        props: {
            categories: {
                type: Array,
                default: () => ([]),
            },
        },
        data() {
            return {
                localItem: {
                    discountValue: '',
                    organizationLink: '',
                    description: '',
                    organizationAddress: '',
                    name: '',
                    categoryId: 0,
                    id: 0,
                },
                mode: null,
                isShowedPopup: false,
                isValidationInitialized: false,
            };
        },
        computed: {
            isShowedScrollbar() {
                return this.isAddMode ? window.innerHeight < 785 : window.innerHeight < 629;
            },
            isEditMode() {
                return this.mode === 'edit';
            },
            isAddMode() {
                return this.mode === 'add';
            },
            isDeleteMode() {
                return this.mode === 'delete';
            },
            validation() {
                const webProtocols = ['https://', 'http://'];
                const validation = {
                    discountValue: !!this.localItem.discountValue.trim().length,
                    organizationLink: webProtocols.some((protocol) => this.localItem.organizationLink.trim().match(protocol)),
                    description: !!this.localItem.description.trim().length,
                    name: !!this.localItem.name.trim().length,
                };
                if (this.isAddMode) {
                    validation.categoryId = !!this.localItem.categoryId;
                }
                return validation;
            },
            modeState() {
                const data = {
                    add: {
                        title: 'Добавить скидку',
                        applyButtonText: 'Сохранить',
                        cancelButtonText: 'Отмена',
                        methodName: 'addDiscount',
                    },
                    edit: {
                        title: 'Редактирование',
                        applyButtonText: 'Сохранить',
                        cancelButtonText: 'Отмена',
                        methodName: 'updateDiscount',
                    },
                    delete: {
                        title: 'Вы уверены, что хотите удалить данную скидку?',
                        applyButtonText: 'Да, продолжить',
                        cancelButtonText: 'Нет, отменить',
                        methodName: 'deleteDiscount',
                    },
                };
                return data[this.mode];
            },
        },
        mounted() {
            this.addEventListeners();
        },
        beforeDestroy() {
            this.removeEventListeners();
        },
        methods: {
            initPopup(data) {
                this.mode = data.mode;
                this.localItem = Object.assign(this.localItem, data.discount);
                this.isShowedPopup = true;
            },
            addEventListeners() {
                eventBus.$on('initPopupDiscount', this.initPopup);
            },
            removeEventListeners() {
                eventBus.$off('initPopupDiscount', this.initPopup);
            },
            closePopup() {
                const newDiscount = {
                    discountValue: '',
                    organizationLink: '',
                    description: '',
                    organizationAddress: '',
                    name: '',
                    categoryId: 0,
                    id: 0,
                };
                this.localItem = Object.assign(this.localItem, newDiscount);
                this.isValidationInitialized = false;
                this.isShowedPopup = false;
            },
            tryToApply() {
                this.isValidationInitialized = true;
                const isValid = Object.values(this.validation).every((val) => val);
                if (isValid || this.isDeleteMode) {
                    this.apply();
                    this.closePopup();
                }
            },
            apply() {
                eventBus.$emit('applyClick', this.modeState.methodName, JSON.parse(JSON.stringify(this.localItem)));
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .popup {
        position: fixed;
        z-index: 1000;
        height: 100vh;
        width: 100vw;
        pointer-events: all;
        display: flex;
        justify-content: center;
        
        &__background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $greyLite;
            opacity: .3;
        }
        
        .wrapper {
            position: relative;
            margin-top: 20px;
            max-height: calc(100vh - 140px);
            z-index: 10;
            display: flex;
            flex-direction: column;
            width: 1035px;
            background-color: $white;
            height: fit-content;
            border-radius: 10px;
            padding: 40px 0 60px 0;
            box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
    
            .button-close {
                cursor: pointer;
                position: absolute;
                top: 23px;
                right: 27px;
                width: 12px;
                height: 12px;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("../../assets/close.svg");
                transition: all .3s;
        
                &:hover {
                    transform: rotate(0.5turn);
                }
            }
            
            &__header {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 26px;
                width: calc(100% - 80px);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                color: $greyDark;
                padding: 0 40px 30px 40px;
            }
            
            &__body {
                scrollbar-color: $green $greyLite;
                scrollbar-width: thin;
                overflow-x: hidden;
                overflow-y: auto;
                padding: 12px 40px;
                max-height: calc(100% - 122px);
                display: grid;
                grid-column-gap: 20px;
                grid-row-gap: 30px;
                grid-template-columns: 4.45fr 1.1fr 3.25fr;
    
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 8px;
                    background-color: $greyLite;
                    &-thumb {
                        background-color: $green;
                        border-radius: 5px;
                    }
                }
              
                .input {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    height: fit-content;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
    
                    &__label {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        height: 100%;
                        margin-bottom: 10px;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 20px;
                        color: $greyMid;
                        text-transform: uppercase;
                    }
                    
                    input {
                        width: calc(100% - 20px);
                        height: 52px;
                        border-radius: 4px;
                        padding-left: 16px;
                        border: 1px solid $greyLite;
                        outline: none;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $greyDark;
                        font-family: 'Nunito', sans-serif;
                        &::placeholder {
                            font-family: 'Nunito', sans-serif;
                            color: $greyMid;
                        }
                    }
                    
                    textarea {
                        width: calc(100% - 32px);
                        resize: none;
                        border-radius: 4px;
                        padding: 16px;
                        border: 1px solid $greyLite;
                        height: 73px;
                        outline: none;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: $greyDark;
                        font-family: 'Nunito', sans-serif;
                        &::placeholder {
                            font-family: 'Nunito', sans-serif;
                            color: $greyMid;
                        }
                    }
                    &_postfixed {
                        position: relative;
                        &::before {
                            font-family: 'Nunito', sans-serif;
                            position: absolute;
                            right: 15px;
                            top: 47px;
                            font-size: 16px;
                            line-height: 24px;
                            color: $greyMid;
                            font-weight: 600;
                        }
                        &.input_value {
                            &::before {
                                content: '%';
                            }
                        }
                    }
                    &_not-valid {
                        input {
                            border: 1px solid $red;
                        }
                        
                        textarea {
                            border: 1px solid $red;
                        }
                    }
                    &_postfixed {
                        position: relative;
                        &::before {
                            font-family: 'Nunito', sans-serif;
                            position: absolute;
                            right: 15px;
                            top: 47px;
                            font-size: 16px;
                            line-height: 24px;
                            color: $greyMid;
                            font-weight: 600;
                        }
                        &.input_value {
                            &::before {
                                content: '%';
                            }
                        }
                    }
                    &_category {
                        grid-column-start: 1;
                        grid-column-end: 4;
                    }
                  
                    &_description {
                        grid-column-start: 1;
                        grid-column-end: 4;
                    }
                    
                    &_address {
                        grid-column-start: 1;
                        grid-column-end: 4;
                    }
                    &_link {
                        position: relative;
                        &_not-valid::after {
                            font-weight: 600;
                            font-family: 'Nunito', sans-serif;
                            text-align: left;
                            content: 'Некорректный формат ссылки. Введите ссылку по примеру "http://site.com", а лучше скопируйте из адресной строки';
                            position: absolute;
                            color: $red;
                            font-size: 12px;
                            line-height: 16px;
                            bottom: -52px;
                            left: 17px;
                        }
                    }
                }
            }
    
            &__footer {
                padding: 24px 40px 0 40px;
                width: calc(100% - 80px);
                display: flex;
                justify-content: flex-end;
                align-items: center;
        
                &__button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 56px;
                    padding: 0 24px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all .3s;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 24px;
            
                    &:hover {
                        box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
                    }
            
                    &_cancel {
                        height: 52px;
                        color: black;
                        border: 2px solid black;
                    }
            
                    &_apply {
                        color: $white;
                        margin-left: 20px;
                        background-color: $green;
                    }
                }
            }
            &_with-scroll {
                .wrapper__header {
                    box-shadow: 0 5px 5px rgba(50, 63, 86, 0.05);
                }
                .wrapper__footer {
                    box-shadow: 0 -3px 3px rgba(50, 63, 86, 0.05);
                }
            }
        }
        &_small {
            .wrapper {
                width: 537px;
                &__body {
                    display: none;
                }
                &__footer {
                    padding-top: 15px;
                    justify-content: center;
                }
            }
        }
    }
</style>