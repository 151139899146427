<template>
    <div class="row">
        <div class="row__column"></div>
        <div class="row__column">
            {{ discount.name }}
        </div>
        <div class="row__column">
            {{ `${discount.discountValue.trim()}%` }}
        </div>
        <div class="row__column row__column_link">
            <span @click="goToOrganizationWebSite(discount.organizationLink)">
                {{ displayedOrganisationLink }}
            </span>
        </div>
        <div class="row__column">
            {{ discount.description }}
        </div>
        <div class="row__column">
            {{ discount.organizationAddress }}
        </div>
        <div class="row__column row__column_settings">
            <template v-if="isEditable">
                <span
                    ref="dropdownMenuToggle"
                    class="row__column_settings__icon"
                    @click="changeDropdownVisibility"
                ></span>
                <div
                    v-if="isShowedDropdownMenu"
                    class="dropdown-menu"
                >
                    <div
                        class="dropdown-menu__button dropdown-menu__button_edit"
                        @click="showPopupDiscount('edit', discount)"
                    >
                        <div class="dropdown-menu__button__icon"></div>
                        Редактировать
                    </div>
                    <div
                        class="dropdown-menu__button dropdown-menu__button_delete"
                        @click="showPopupDiscount('delete', discount)"
                    >
                        <div class="dropdown-menu__button__icon"></div>
                        Удалить
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {eventBus} from '../../../main';
    import clickOutside from '../../../mixins/clickOutside';
    
    export default {
        name: 'TableRow',
        mixins: [
            clickOutside,
        ],
        props: {
            categoryId: {
                type: Number,
                default: 0,
            },
            discount: {
                type: Object,
                default: () => ({}),
            },
            isEditable: {
                type: Boolean,
                required: true,
                default: false,
            },
        },
        data() {
            return {
                isShowedDropdownMenu: false,
            };
        },
        computed: {
            displayedOrganisationLink() {
                let link;
                const httpProtocol = 'http://';
                const httpsProtocol = 'https://';
                if (this.discount.organizationLink.match(httpsProtocol)) {
                    link = this.discount.organizationLink.slice(httpsProtocol.length, this.discount.organizationLink.length);
                } else if (this.discount.organizationLink.match(httpProtocol)) {
                    link = this.discount.organizationLink.slice(httpProtocol.length, this.discount.organizationLink.length);
                } else {
                    link = this.discount.organizationLink;
                }
                return link;
            },
        },
        methods: {
            showPopupDiscount(mode, discount) {
                const data = {
                    mode,
                    discount: Object.assign({categoryId: this.categoryId}, discount),
                };
                eventBus.$emit('initPopupDiscount', data);
            },
            goToOrganizationWebSite(link) {
                window.open(link);
            },
            changeDropdownVisibility() {
                this.isShowedDropdownMenu = !this.isShowedDropdownMenu;
                if (this.isShowedDropdownMenu) {
                    this.initClickOutsideListener({
                        key: 'tableRow',
                        el: this.$refs.dropdownMenuToggle,
                        callback: () => {
                            this.isShowedDropdownMenu = false;
                        },
                    });
                } else {
                    this.destroyEventClickOutsideListener('tableRow');
                }
            },
        },
    };
</script>

<style
    scoped
    lang="scss"
>
    @import "src/styles/colors";
    
    .row {
        width: calc(100% - 4px);
        display: grid;
        grid-template-columns: 31px .7fr .3fr .5fr 1fr .4fr 60px;
        background-color: $white;
        border-bottom: 1px solid $greyLite;
        border-left: 4px solid $green;
        
        &__column {
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding: 12px 15px;
            word-break: break-word;
            
            &_link {
                span {
                    cursor: pointer;
                    color: $green;
                    text-decoration: underline;
                    word-break: break-word;
                }
            }
            
            &_settings {
                display: flex;
                position: relative;
                
                &__icon {
                    opacity: .5;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    transition: all .3s;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-image: url("../../../assets/menu.svg");
                    
                    &:hover {
                        opacity: 1;
                    }
                }
                
                .dropdown-menu {
                    top: 30px;
                    right: -20px;
                    position: absolute;
                    z-index: 5;
                    background-color: $white;
                    border-radius: 4px;
                    width: 192px;
                    box-shadow: 0 4px 21px rgba(50, 63, 86, 0.11);
                    padding: 12px 0;
                    
                    &__button {
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        width: 100%;
                        height: 40px;
                        padding: 0 20px;
                        transition: all .3s;
                        
                        &__icon {
                            width: 15px;
                            height: 15px;
                            background-repeat: no-repeat;
                            background-position: center;
                            margin-right: 12px;
                        }
                        
                        &_edit {
                            .dropdown-menu__button__icon {
                                background-image: url("../../../assets/edit.svg");
                            }
                            
                        }
                        
                        &_delete {
                            color: #BE3B26;
                            
                            .dropdown-menu__button__icon {
                                background-image: url("../../../assets/delete-button_small.svg");
                            }
                        }
                        
                        &:hover {
                            background-color: $greyLiter;
                        }
                    }
                }
            }
        }
    }
</style>